<template>
  <!-- 弹窗 -->
  <SDialog
    ref="recommendDialog"
    v-model:visible="visible"
    type="W996"
    :append-to-body="true"
    show-close
    class="recommend-dialog"
    da-expose-code="recommendDialogCode"
    @close="$emit('update:visible', false)"
  >
    <template #title>
      <div class="recommend-dialog__title">
        {{ title }}
      </div>
    </template>
    <div
      v-if="isInited"
      class="recommend-dialog__container j-expose__recommend-list j-da-event-box"
      code="goodsList"
      :data-abt-info="listPoskey"
      :data-promo-list="(catInfo.type === 'picks' && catInfo.picks_id) || ''"
      :data-promo-list-type="
        (catInfo.type === 'picks' && catInfo.sub_type) || ''
      "
      data-module="true"
      data-src-module="feedback"
      data-active-from="realtime_feedback_popup"
      :data-page-num="catInfo.page || 1"
      :data-poskey="listPoskey"
      :data-page-sort="catInfo.sort || 0"
      :data-tag_ids="catInfo.tag_ids"
      :data-attr_str="catInfo.attr_ids"
      :data-cat="catInfo.child_cat_id"
      :data-min-pri="catInfo.min_price"
      :data-max-pri="catInfo.max_price"
      :data-src-identifier="`on=${item.goods_id}`"
      :data-beacon="0"
    >
      <div
        v-for="(obj, index) in list"
        :key="obj.goods_id"
        class="recommend-dialog__item"
      >
        <ProductItem
          ref="productItem"
          class="product-list__item-new"
          :item="obj"
          :index="index"
          :config="productItemConfig"
          :language="language"
          open-target="_blank"
          :goods-item-info="goodsItemInfo"
          :constant-data="constantData"
          :loading="quickViewLoading[obj.goods_id]"
          @add-bag-before-request="handleAddTobagBtn"
          @add-wish-succ="handleAddWishSucc"
          @add-wish-analysis="handleAddWishAnalysis"
          @add-wishlist-group="handleAddWishlistGroup"
          @submit-add-bag-analysis="handleSubmitAddBagAnalysis"
          @click-add-to-bag="openQuickView"
          @open-quick-add="openQuickView"
        />
      </div>
    </div>

    <div
      v-else
      class="recommend-dialog__skeleton"
    >
      <ProductListSkeleton
        :num="20"
      />
    </div>
  </SDialog>
</template>

<script>
import { throttle } from 'lodash'
import schttp from 'public/src/services/schttp'
import { SDialog } from '@shein-aidc/sui-dialog'
import { getQueryString } from '@shein/common-function'
// import ProductItem from 'public/src/pages/components/productItem/ProductItem.vue'
import ProductItem from 'public/src/pages/components/productItemV3/MultipleImageRowCard.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { groupDialog } from 'public/src/pages/components/wishlist'
import ProductListSkeleton from 'public/src/pages/product_list_v2/components/ProductListSkeleton'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import { windowScrollInstance } from 'public/src/services/expose/index.js'
import { feedbackAbtToRuleId } from '../utils/index'
const daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'RecommendDialog',
  components: {
    SDialog,
    ProductItem,
    ProductListSkeleton
  },
  inject: ['getGoodsUrl'],
  emits: ['update:visible'],
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    listAbtResult: {
      type: Object,
      default: () => ({}),
    },
    atomParams: {
      type: Object,
      default: () => ({}),
    },
    showConfig: {
      type: Object,
      default: () => ({}),
    },
    catInfo: {
      type: Object,
      default: () => ({}),
    },
    constantData: {
      type: Object,
      default: () => ({}),
    },
    listPoskey: {
      type: String,
      default: '',
    },
    triggerEvent: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      list: [],
      isInited: false,
      loading: true,
      pageLimit: 20,
      pageNum: 1,
      pageTotal: 0,
      quickViewLoading: {}, // quickView的loading状态
      goodsItemInfo: null,
      goodsMap: {},
      isNoMore: false,
      suggestedSalePriceType: '', // wiki: pageId=1347754607 新增用
    }
  },
  computed: {
    productItemConfig() {
      return {
        ...this.showConfig,
        showAddWishlist: false,
        hideOperationBtn: true,
        showAddBagAfterGoodsName: true,
        showImgMask: false,
        standardPrice: !!this.suggestedSalePriceType,
        hidePromotionLabel: true,  // 不展示促销类标签（解决服务类标签出现1 的问题）
        // showSheinClubDiscountValue: showOldSheinClubTag,
        // showSheinClubDiscountValueAfterPrice: showNewSheinClubTag,
        // showSheinClubPriceOnSale: isPaid,
        // showEstimatedPriceOnSale: true, // 到手价-占用原来的销售价位置
        // noNeedAnyEstimatedPriceTag: true, // 到手价不需要展示任何标签
      }
    }
  },
  watch: {
    visible: {
      handler(val) {
        val && this.initList()
      },
      immediate: true
    }
  },
  async mounted() {
    this.goodsItemInfo = new GoodsItemInfo({
      listLanguage: this.language,
      getGoodsUrl: this.getGoodsUrl,
      // itemConfig: this.productItemConfig
    })
  },
  beforeUnmount() {
    this.removeEvent()
  },
  methods: {
    async initList() {
      this.isInited = false
      this.list = []
      this.pageNum = 1
      this.isNoMore = false
      this.list = await this.getList()
      this.isInited = true

      this.$nextTick(() => {
        this.scrollToTop()
        this.initExpose()
        this.bindEvent()
        this.scrollInstanceUpdate()
      })
    },

    bindEvent() {
      this.bindScroll()
    },
    removeEvent() {
      this.removeScroll()
    },
    bindScroll() {
      const dom = this.$refs.recommendDialog?.$el.querySelector('.sui-dialog__body')
      if (!dom) return

      dom.addEventListener('scroll', this.scrollMore)
    },
    removeScroll() {
      const dom = this.$refs.recommendDialog?.$el.querySelector('.sui-dialog__body')
      if (!dom) return

      dom.removeEventListener('scroll', this.scrollMore)
    },

    scrollMore: throttle(async function (event) {
      const { target } = event
      const childHeight = target.querySelector('.recommend-dialog__item')?.offsetHeight || 100
      const diff = target.scrollHeight - target.scrollTop - target.clientHeight
      if (this.loading || this.isNoMore || diff > childHeight) return


      this.pageNum++
      const list = await this.getList()
      this.list.push(...list)

      if (list.length === 0 || this.list.length >= this.pageTotal) {
        this.isNoMore = true
      }

      this.$nextTick(() => {
        this.initExpose()
        this.scrollInstanceUpdate()
      })
    }, 300),

    scrollInstanceUpdate() {
      windowScrollInstance.update('recommend-dialog')
    },


    async getList() {
      this.loading = true
      const result = await schttp({
        method: 'POST',
        url: '/api/recommend/facadeAtom/get',
        data: {
          limit: this.pageLimit,
          pageNum: this.pageNum,
          req_num: 200,
          scene_id: 156,
          location: 'RealtimeFeedbackJson',
          jsonRuleId: JSON.stringify(feedbackAbtToRuleId(this.listAbtResult, this.triggerEvent)),
          isPaid: this.atomParams?.isPaid,
          newPreHandle: true,
          // wiki: pageId=1347754607 添加
          preGoodsHandlerConfig: {
            hideSuggestedSalePrice: true
          },
          contextParams: {
            goods_ids: this.item.goods_id,
            cate_id: this.item.cat_id,
            discount_rate: (this.item.unit_discount / 100) || 0,
            goods_price: this.item.salePrice?.usdAmount || '',
            click_nav_attr: getQueryString({ key: 'attr_ids' }) || ''
          },
          atomFields: {
            mallCodeList: true,
            plusSize: true,
            rankingList: true,
            locateLabels: 'default',
            newCard: true,
            relatedProducts: true,
            baseInfo: true, 
            prices: true,
            stock: true,
            realTimePricesWithPromotion: true,
            vipDiscountPrices: true,
            detailImage: true,
            sheinClubPromotionInfo: true,
            newFlashPromotion: true,
            estimatedPrice: true, // 到手价
            promotion: true,
            userCommentKeywordsLabel: true,
            userPreferences: true, // 注意看中间层
            priceDiscountDesc: true,
            seriesAndBrand: true,
            sellingPoints: true,
            cccTspBadges: true,
            starComment: true,
            userBehaviorLabel: true,
            beltLabel: true,
            serviceLabel: true,
            labelsBeforeGoodsName: true,
            salesLabel: true,
            flashZonePromotion: true,
            horseRaceLabel: true,
            cornerPropertyUpsell: true,
            quickShip: true,
            ...this.atomParams.fields, // (外层处理atomParams.fields 的逻辑存在问题,总之就是少东西)
          },
          atomScene: {
            pageKey: this.atomParams.pageKey,
            sceneKey: this.atomParams.sceneKey,
          },
          atomCccParams: this.atomParams.cccParams,
          abtInfoV3: {
            newProductCard: true,
          },
        }
      })

      let { total = 0, products = [], suggestedSalePriceType = '' } = result?.info || {}
      this.suggestedSalePriceType = suggestedSalePriceType

      // 过滤售罄商品
      products = products.filter(item => !item.pretreatInfo?.saleout?.show)


      products.forEach((item, index) => this.dealGood(item, index))
      this.pageTotal = total
      this.loading = false
      return products
    },
    // 曝光埋点
    initExpose() {
      windowScrollInstance.observe(
        {
          section: [
            {
              code: 'recommend-dialog',
              container: '.j-expose__recommend-list',
              content: '.j-expose__product-item',
              target: '.j-expose__product-item-img',
              averageContent: false,
            },
          ],
        },
        ({ exposeDoms: targets }) => {
          // 多颜色切换时goodsMap没有对应的goodsId，不需要请求
          let inScreenGoods = []
          targets.forEach((el) => {
            let goodsId = el.getAttribute('data-id')
            if (!this.goodsMap[goodsId]) return
            inScreenGoods.push(this.goodsMap[goodsId])
          })
          // preload relatecolor info，relatecolor goods img
          if (inScreenGoods.length > 0)
            this.goodsItemInfo.getRelateColorProductInfo({
              goods: inScreenGoods,
            })
        }
      )
      daEventExpose.subscribe({
        keycode: `recommendDialogCode\`${ this.productItemConfig.daEventExposeId || '' }`,
        type: 'list',
      })
    },
    // 翻页后滚动条归0
    scrollToTop(){
      document.querySelector('.recommend-dialog__container')?.parentElement?.scrollTo?.({
        top: 0,
        behavior: 'smooth'
      })
    },
    dealGood(item, index) {
      item.index = index

      const {
        src_tab_page_id,
        attr_ids = '',
      } = this.catInfo || {}


      let biQueryParams = {
        attr_ids,
        src_module: 'feedback',
        src_identifier: `on=${item.goods_id}`,
        src_tab_page_id,
      }

      const mallCode = item.mall_code ? { mallCode: item.mall_code } : {}
      this.goodsItemInfo.dealGoodsDetailUrl({
        goodsItem: item,
        query: {
          ...biQueryParams,
          ...mallCode
        },
      })
      if (!this.$isServer) {
        item.relatedColor &&
          item.relatedColor.forEach((relateColorItem) => {
            const mallCode = relateColorItem.mall_code
              ? { mallCode: relateColorItem.mall_code }
              : {}
            relateColorItem.index = index
            this.goodsItemInfo.dealGoodsDetailUrl({
              goodsItem: relateColorItem,
              query: {
                ...biQueryParams,
                ...mallCode
              },
            })
          }) 
        this.goodsMap[item.goods_id] = item
      }
      index += 1
    },
    async handlePageChange(offset) {
      this.pageNum = offset
      this.list = await this.getList()
    },
    handleAddTobagBtn({ goodsItem = {} } = {}) {
      gbAddBagTraceFrom('setProductFrom', {
        from: 'list',
        goods_id: goodsItem.goods_id,
      })
    },
    handleAddWishSucc() {
      fastwish?.reAddwish?.()
    },
    handleAddWishAnalysis({
      target,
      code,
      from,
      result,
      action,
      sku,
      goods_id,
      detail,
      index,
      mall_code,
    }) {
      daEventCenter.triggerNotice({
        daId: '1-7-1-2',
        target,
        extraData: {
          code,
          result,
          mall_code: mall_code || '',
          postData: {
            action, // 选中发1, 取消发0
            sku,
            goods_id,
            detail,
            index,
            ...(this.goodsItemInfo.showQuickShip ? { quickShip: detail.quickship } : {})
          },
          from,
          cat_info: this.catInfo,
        },
      })
    },
    handleAddWishlistGroup({ goodsItem = null } = {}) {
      if (!goodsItem) return

      daEventCenter.triggerNotice({
        daId: '1-4-1-9',
      })
      groupDialog().then(dialog => dialog.open(
        {
          type: 4,
          goodsIds: [goodsItem.goods_id],
          useFrom: '列表页',
        }, () => {}))
    },
    handleSubmitAddBagAnalysis({
      target,
      code,
      from,
      response,
      goodsItem,
      reason,
      attrs,
      selectedSize,
      skuCode,
    }) {
      daEventCenter.triggerNotice({
        daId: '1-7-1-1',
        target,
        extraData: {
          code,
          from,
          result: response,
          title: '',
          postData: {
            goods_id: goodsItem.goods_id,
            sku: goodsItem.goods_sn,
            spu: goodsItem.productRelationID || goodsItem.goods_sn,
            price: GB_ga_transformPrice(goodsItem?.salePrice?.amount),
            quantity: 1,
            catId: goodsItem.cat_id || '',
            variant: selectedSize.attr_value_en || '',
            amount: goodsItem?.salePrice?.amount,
            unit_discount: goodsItem.unit_discount || '',
            index: goodsItem.index || 0,
            attrs,
            skuCode,
          },
          faultReason: reason,
          isNew: goodsItem?.pretreatInfo?.badgeInfo?.isNewTag ? 1 : 0,
          traceid: gbExposeTraceid('getComponent', {
            componentName: 'goodslist',
          }),
          cat_info: this.catInfo,
        },
      })
    },
    openQuickView({ goodsItem, target }) {
      this.quickViewLoading[[goodsItem.goods_id]] = true
      const {
        src_tab_page_id,
      } = this.catInfo || {}
      let detailUrlExtendParam = {
        src_module: 'feedback',
        src_identifier: `on=${goodsItem.goods_id}`,
        src_tab_page_id,
      }
      import('public/src/pages/common/quickView').then(
        ({ default: quickView }) => {
          quickView.show({
            goodsId: goodsItem.goods_id,
            mallCode: goodsItem.mall_code,
            lockmall: !!this.catInfo.lockMall,
            config: {
              showBestDealLabel: true,
              showFollowBeltByOrigin: true,
              showEstimatedPrice: true,
              detailUrlExtendParam,
            },
            analysis: {
              sourceTarget: target,
              pageType: 'ItemList',
              sa: {
                code: 'goodsList',
                activity_from: 'realtime_feedback_popup',
              },
              extendAnalysis: {
                isNew: goodsItem?.pretreatInfo?.badgeInfo?.isNewTag ? 1 : 0,
                traceid: gbExposeTraceid('getComponent', {
                  componentName: 'goodslist',
                }),
                cat_info: this.catInfo,
              },
            },
            callback: {
              handleOpenQuickViewDone: () => {
                this.quickViewLoading[[goodsItem.goods_id]] = false
              },
              handleOpenLogin(fetchAddLike) {
                SHEIN_LOGIN.show({
                  cb: () => fetchAddLike(),
                  from: 'goods_list',
                })
              },
              handleAddWishDone: (goodsId) => {
                const goodsItem = this.list.find((_) => _.goods_id == goodsId)
                goodsItem &&
                  this.goodsItemInfo.syncWishStatus({
                    goodsItem,
                    params: { status: true },
                  })
              }
            },
          })
        }
      )
    }
  }
}
</script>

<style lang="less" scoped>
.recommend-dialog__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 34px;
  color: #222222;
}


.recommend-dialog__container{
  .flexbox();
  flex-wrap: wrap;

  .recommend-dialog__item {
    width: 20%;
    // padding: 10px;
  }
}
.product-list__item-new {
  padding: 10px;
}
</style>

<style lang="less">
.recommend-dialog__skeleton {
  > div{
    .flexbox();
    flex-wrap: wrap;

    /* stylelint-disable-next-line selector-max-type */
    >div {
      width: 20%;
      padding: 10px;
    }
  }
}
</style>
